// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-temp-js": () => import("./../../../src/pages/temp.js" /* webpackChunkName: "component---src-pages-temp-js" */),
  "component---src-templates-achievement-js": () => import("./../../../src/templates/achievement.js" /* webpackChunkName: "component---src-templates-achievement-js" */),
  "component---src-templates-achievement-list-js": () => import("./../../../src/templates/achievementList.js" /* webpackChunkName: "component---src-templates-achievement-list-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/articleList.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-opinion-list-js": () => import("./../../../src/templates/opinionList.js" /* webpackChunkName: "component---src-templates-opinion-list-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

